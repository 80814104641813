/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import '~@angular/material/prebuilt-themes/indigo-pink.css';

@import '~quill/dist/quill.snow.css';


/* .mat-dialog-container {
    padding: 0 !important;
} */
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.cdk-overlay-container {
    z-index: 1020; // header-fixed has z-index 1010. So it has to be greater
}

@media (min-width: 576px) { 
  .nk-content { padding: 32px 0px !important; } 
}


.review-order-dialog .mat-dialog-container {
  overflow-y: hidden;
  overflow-x: hidden;
}

